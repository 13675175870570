//@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "~bootstrap/scss/bootstrap";

$theme:#622252;
$progress-bar: #622252;
$white:#ffffff;
$black:#000000;
$darkgray: #363636;
$button: #622252;
$body: #535353;
$answer-btn-hover: #86216D;



.btn-success {
    --bs-btn-color: #fff;
    --bs-btn-bg: #622252;
    --bs-btn-border-color: #622252;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #D09BC3;
    --bs-btn-hover-border-color: #D09BC3;
    --bs-btn-focus-shadow-rgb: 60, 153, 110;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #86D0CE;
    --bs-btn-active-border-color: #86D0CE;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #86D0CE;
    --bs-btn-disabled-border-color: #86D0CE;
}